<!-- 首页  -->
<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <h3><i class="el-icon-data-analysis" style="color:#67C23A"></i> 系统概况</h3>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span @click="keyDialogVisible=true"><i class="el-icon-key" style="color:#67C23A"></i> 授权证书
              <i class="el-icon-check" v-if="cerInfo.very" style="color:#67C23A">正常</i>
              <el-tooltip class="item" effect="light" :content="cerInfo.veryMsg" placement="top" v-else>
                <i class="el-icon-close" style="color:#F56C6C">失效</i>
              </el-tooltip>
            </span>
            <el-upload ref="cerUpload" action="" :http-request="cerUploadSubmit" :before-upload="cerSubmitBefore" :on-error="notifyError"
              :show-file-list="false" style="float: right; padding: 0;margin: 0px;">
              <el-button type="text" style="padding: 0 3px;">更换</el-button>
            </el-upload>
          </div>
          <div class="text item">
            <el-form label-width="80px" size="mini">
              <el-form-item label="证书ID">{{cerInfo.cerCode}}</el-form-item>
              <el-form-item label="授权">{{cerInfo.cerInfo.ST}}-{{cerInfo.cerInfo.L}} {{cerInfo.cerInfo.CN}}</el-form-item>
              <el-form-item label="联系">{{cerInfo.cerInfo.TelephoneNumber}} E-mail:{{cerInfo.cerInfo.E}}</el-form-item>
              <el-form-item label="有效期至">{{cerInfo.cerInfo.endDate}}
                <el-tag v-if="cerInfo.cerInfo.day>0">{{cerInfo.cerInfo.day}}天</el-tag>
                <el-tag type="danger" v-else>{{cerInfo.cerInfo.day}}天</el-tag>
              </el-form-item>
            </el-form>
          </div>
          <el-dialog v-dialogDrag title="证书信息" :visible.sync="keyDialogVisible" width="30%">
            <el-input type="textarea" style="margin-top:10px;border:0px;" resize="none" rows="10" v-model="cerInfo.cerInfo.key" readonly=""></el-input>
          </el-dialog>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span><i class="el-icon-coin" style="color:#67C23A"></i> 磁盘存储</span>
            <router-link :to="{name: 'backUp'}"><el-button type="text" style="padding: 0 3px;float: right;">备份管理</el-button></router-link>
          </div>
          <div class="text item" style="font-size: 14px;">
            <el-row :gutter="10">
              <el-col :span="3">盘</el-col>
              <el-col :span="3" style="text-align:right;">类型</el-col>
              <el-col :span="6" style="text-align:center;">大小</el-col>
              <el-col :span="12" style="text-align:center;">占比</el-col>
            </el-row>
            <el-row :gutter="10" v-for="(item,index) in this.diskInfo" :key="'diskInfo'+index">
              <el-col :span="3">
                {{item.diskDirName}}</el-col>
              <el-col :span="3" style="text-align:right;">
                {{item.diskSysTypeName}}</el-col>
              <el-col :span="6" style="text-align:right;">{{(item.diskUsed/1024).toFixed(2)}}/
                {{(item.diskTotal/1024).toFixed(2)}}G</el-col>
              <el-col :span="12">
                <el-progress :text-inside="true" :stroke-width="18" :color="diskColors"
                  :percentage="countPercentage(item.diskUsed,item.diskTotal)"></el-progress>

              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span><i class="el-icon-cpu" style="color:#67C23A"></i> 系统信息 </span>
          </div>
          <div class="text item">
            <el-form label-width="80px" size="mini">
              <el-form-item label="操作系统">{{sysInfo.osName}} {{sysInfo.osArch }} {{sysInfo.osVersion}}</el-form-item>
              <el-form-item label="Java版本">{{sysInfo.jvmName}} {{sysInfo.javaVersion}}</el-form-item>
              <!-- <el-form-item label="Java版本">{{sysInfo.javaVersion}} ({{sysInfo.jvmVersion}})</el-form-item> -->
              <el-form-item label="平台路径">JAVA_HOME:{{sysInfo.javaHome}}</el-form-item>
              <el-form-item label="已用内存">
                <el-progress :text-inside="true" :stroke-width="20"
                  :percentage="countPercentage(this.sysInfo.jvmTotalMem - this.sysInfo.jvmFreeMem,this.sysInfo.jvmTotalMem)"></el-progress></el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<style>
.my-label {
  background-color: #f56c6c;
  padding: 0 3px;
  color: #fff;
  font-size: 16px;
}
</style>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script> 
import store from '@/store';
import { getCer, getDiskInfo, getSys, postCerFile } from '@/api/wbkj/Home';
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  data () {
    //这里存放数据
    return {
      colors: [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
      ],
      diskColors: [
        { color: '#6f7ad3', percentage: 20 },
        { color: '#1989fa', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#e6a23c', percentage: 80 },
        { color: '#f56c6c', percentage: 100 },
      ],

      keyDialogVisible: false,
      selectYear: '2023',
      selectGroup: 1,
      years: [
        {
          value: '2021',
          label: '2021年'
        },
        {
          value: '2022',
          label: '2022年'
        }, {
          value: '2023',
          label: '2023年'
        }, {
          value: '2024',
          label: '2024年'
        }, {
          value: '2025',
          label: '2025年'
        }, {
          value: '2026',
          label: '2026年'
        }, {
          value: '2027',
          label: '2027年'
        }],
      cerInfo: { cerInfo: { ST: '' } },
      sysInfo: {},
      diskInfo: {},
      allProject: [], //项目数据
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},

  //方法集合
  methods: {
    /*上传cer文件类型判断,默认doc,docx,pdf是都可以上传*/
    cerSubmitBefore (file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.'))
      if ('.cer'.indexOf(fileType) == -1) {  //允许的文件类型请自己修改,但请注意,是带.的
        this.notifyWarning('上传失败,请上传.cer文件')
        return false;
      }
      else {
        return true;
      }
    },
    cerUploadSubmit (param) {
      const cerFileData = new FormData()
      cerFileData.append('file', param.file);
      postCerFile(cerFileData).then(response => {
        if (response.data.success) {
          this.cerInfo = response.data.data;
          param.onSuccess();  // 上传成功的图片会显示绿色的对勾
        }
        else {
          param.onError(response.data.msg);//会去调用出错的方法
        }
      }).catch(error => {
        param.onError(error); //会去调用出错的解决办法
      })
    },
    /*特殊事件-下载文件 */
    echartsInit () {
      //柱形图
      //因为初始化echarts 的时候，需要指定的容器 id='main'
      this.$echarts.init(document.getElementById('main')).setOption(this.countOption);
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {


  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    //取得全部部门
    getCer().then(response => {
      if (response.data.success) {
        this.cerInfo = response.data.data;
      }
      else {
        this.cerInfo = response.data.data;
        this.notifyWarning(response.data.msg);
      }
    })
    getSys().then(response => {
      if (response.data.success) {
        this.sysInfo = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
    getDiskInfo().then(response => {
      if (response.data.success) {
        this.diskInfo = response.data.data;

      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
