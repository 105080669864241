<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.tableParam.kindName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="6">
        <el-select v-model="tableParam.kindId" filterable placeholder="请选择分类" style="width:100%;" ref="selectKind" @change="changeKind">
          <el-option v-for="(item,index) in this.allKinds" :key="index" :label="item.kindName" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%" @change="tableChangeSearchKey">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-button-group>
          <el-button @click="formDataNew" type="primary">新建</el-button>
          <el-button @click="formDataModify" type="primary">修改</el-button>
          <el-button @click="formDataDelete" type="primary">删除</el-button>

          <el-button @click="excelUploadParam.visible=true" type="success" v-if="checkCusRight(direcParam.id,'inExcel')">导入Excel</el-button>
          <el-button @click="outPutExcel" type="success">导出Excel</el-button>
        </el-button-group>
      </el-col>
      <el-col :span="12">
        <el-radio-group v-model="tableParam.selectColor" @change="filterShowFields()" size="mini">
          <el-radio-button label="isDefault"><i class="el-icon-star-on" style="color:#67C23A;font-size:20px"></i></el-radio-button>
          <el-radio-button label="isRed"><i class="el-icon-star-on" style="color:red;font-size:20px"></i></el-radio-button>
          <el-radio-button label="isOrange"><i class="el-icon-star-on" style="color:orange;font-size:20px"></i></el-radio-button>
          <el-radio-button label="isYellow"><i class="el-icon-star-on" style="color:yellow;font-size:20px"></i></el-radio-button>
          <el-radio-button label="isGreen"><i class="el-icon-star-on" style="color:green;font-size:20px"></i></el-radio-button>
          <el-radio-button label="isBlue"><i class="el-icon-star-on" style="color:Blue;font-size:20px"></i></el-radio-button>
          <el-radio-button label="isPurple"><i class="el-icon-star-on" style="color:Purple;font-size:20px"></i></el-radio-button>
          <el-radio-button label="isDark"><i class="el-icon-star-on" style="color: #000;font-size:20px"></i></el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>

    <el-table ref="detailsTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" label="ID" prop="cardID" min-width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.cardID)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" v-for="(item,index) in this.showFileds" :key="index" :label="item.fieldName" :prop="'infoJson.'+item.enName"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column header-align="center" label="创建日期" prop="createDate" min-width="100">
      </el-table-column>
    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>
    <el-dialog v-dialogDrag class="customFileListCss" title="导入Excel" :visible.sync="excelUploadParam.visible" width="50%" top="5vh"
      :close-on-click-modal='false'>
      <el-form ref="formRef" label-width="90px" size="small" :rules="excelDataRules">
        <el-form-item label="选择Excel" prop="fileName">
          <upload-file id="inExcelDataUp" :inputValue="excelData.fileName" label="选择Excel" allow=".xls,.xlsx" childDir="details" :readOnly="true"
            @success="inExcelSuccessUpload"></upload-file>
        </el-form-item>
        <el-form-item label="注意事项">
          <el-row>
            <el-col :span="12">1.导入的模板和导出的模板制式相同,导出即可以导入</el-col>
            <el-col :span="12">2.行头的中文名称不能相同,如果相同,后列覆盖前列数据</el-col>
            <el-col :span="12">3.第二次导入相同结构的表(表头相同),不需要再次设定字段对照</el-col>
            <el-col :span="12">4.正式数据从第二行开始</el-col>
            <el-col :span="12">5.如果存在不正确的字段对照,会导入失败</el-col>
            <el-col :span="12">6.唯一标识可以是Id或手机或身份证号,必须要有值</el-col>
            <el-col :span="12">7.唯一标识值相同,新数据会覆盖旧数据,更新时间发生变化</el-col>
            <el-col :span="12">8.部分其它系统导出的Excel是XML文件,请先另存XLSX,再导入</el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="excelUploadParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="excelDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!--编辑的表单-->
    <el-dialog v-dialogDrag class="customFileListCss" :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width"
      :top="formDialogParam.top" :close-on-click-modal='false'>
      <el-row>
        <el-col :span="20">
          <el-form ref="detailsFormRef" :model="formData" :rules="formDialogRules" label-width="280px" size="small"><!-- `prop` 为绑定的规则 -->
            <el-form-item :label="item.fieldName" :prop="item.enName" :key="item.enName+index" v-for="(item,index) in this.allFileds">
              <el-input :id="item.enName" v-model="formData.infoJson[item.enName]" :placeholder="'请填写'+item.fieldName"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4">&nbsp;
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="30%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="cardID" label="选定的记录">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>

import store from '@/store';
import UploadFile from '@/components/utils/form/UploadFile.vue';
import JsonInfoInfoJson from '@/components/details/JsonInfoInfoJson.vue';
import { getAllKinds } from '@/api/wbkj/Kinds';
import { getAllDataByKindId } from '@/api/wbkj/Fields';
import { getTableDataByKindId, doNew, doModify, doDelete, inExcel } from '@/api/wbkj/Details'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required, phone, len, email } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: { JsonInfoInfoJson, UploadFile },
  data () {
    return {

      allKinds: [],//所有的分类
      allFileds: [],
      /*导航栏状态 */
      showFileds: [],
      direcParam: {
        'directName': '名称名称名称名称名称名称名称名称名称Details'
      },
      excelUploadParam:
      {
        visible: false,
      },
      excelData: { serverPath: '', fileName: '' },
      excelDataRules: { fileName: [required] },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'kindId': 0,
        'kindName': '',
        'tableKey': 0,
        'selectColor': 'isDefault',
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '50%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        kindId: 0,
        cardID: '',
        infoJson: {},
        createDate: new Date(),

      },
      formDialogRules:
      {
        kindId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cardID: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        infoJson: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {
    inExcelSuccessUpload (data) { //上传Excel成功,更新数据
      this.excelData.serverPath = data.serverPath;
    },
    excelDataSubmit () {
      if (this.excelData.serverPath == '') {
        this.notifyWarning("请选择并上传一个Excel文件");
        return;
      }
      const postData = JSON.parse(JSON.stringify(this.excelData));
      this.$set(postData, 'kindId', this.tableParam.kindId);
      //postData,append('kindId', this.tableParam.kindId);
      inExcel(postData).then(response => {
        if (response.data.success) {
          this.tableParam.key = '';
          this.tableParam.page = 1;
          this.notifySuccess(response.data.msg);
          this.tableDateRefresh();
          this.excelUploadParam.visible = false
          this.excelData.serverPath = '';
          this.excelData.fileName = '';
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })

    },
    outPutExcel () {  //小文件导出可以这样写,大文件,需要用到filesaver
      const queryString = Object.keys(this.tableParam)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.tableParam[key]))
        .join('&');
      saveAs('/wbkj/details/outExcel?downToken=' + store.getters.getToken() + '&' + queryString, this.tableParam.kindName + '.xlsx');

    },

    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      if (this.tableParam.kindId > 0) {
        this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
        this.formData.kindId = this.tableParam.kindId;
        this.formDialogParam.title = '新建';
        this.formDialogParam.formDataAction = 'new';
        this.formDialogParam.visible = true;
      }
      else {
        this.notifyInfo("您只能先选一个分类,才能修改");
      }
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(已发布的不删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDataValidate () {
      let result = true;
      this.$refs.detailsFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.infoJson = JSON.stringify(this.formData.infoJson);
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.formDialogParam.visible = true;
            this.notifyWarning(response.data.msg);

          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.formDialogParam.visible = true;
            this.notifyWarning(response.data.msg);

          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      this.$refs.detailsTableRef.clearSelection(); //清除选中
      this.$refs.detailsTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableDataByKindId(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },
    changeKind (val) {
      this.tableParam.kindId = val;
      this.$nextTick(() => {
        this.getKindFields(this.tableParam.kindId);
        this.tableParam.kindName = this.$refs.selectKind.selectedLabel;//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
        this.tableDateRefresh();
      })
    },

    infoJsonSuccess (data) { //JsonInfo,数据回传infoJson成功,更新数据
      this.formData.infoJson = data;
    },
    //过滤显示字段
    filterShowFields () {
      if (this.tableParam.selectColor)//表示有值
      {
        let fileName = this.tableParam.selectColor;
        this.showFileds = this.allFileds.filter(item => !item.isLock && (item[fileName]));
      }
      else {
        this.showFileds = this.allFileds.filter(item => !item.isLock);
      }
    },
    getKindFields (kindId) {
      getAllDataByKindId({ "kindId": kindId }).then(response => {
        if (response.data.success) {
          this.allFileds = response.data.data;
          this.filterShowFields();
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    }
  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    getAllKinds().then(response => {
      if (response.data.success) {
        this.allKinds = response.data.data;
        if (this.allKinds.length > 0) {
          this.tableParam.kindId = this.allKinds[0].id;
          this.getKindFields(this.tableParam.kindId);
          this.tableParam.kindName = this.allKinds[0].kindName;
          this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
          this.tableDateRefresh();

        }
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
  }
}
</script>
