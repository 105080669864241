import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个Details接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/details/list', { 'params': tableParam })
}
export const getTableDataByKindId = (tableParam) => {
    return httpJson.get('/details/listByKindId', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/details/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/details/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/details/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/details/delete', formData)
}
export const inExcel = (formData) => {
    return httpForm.post('/details/inExcel', formData)
}

