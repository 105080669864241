import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminMain from '@/views/AdminMain.vue'
import HomeIndex from '@/views/home/AdminHome.vue' //管理员主页
import DocInfo from '@/views/wbkj/DocInfo.vue'
import Downloads from '@/views/wbkj/DownloadsSet.vue'
import Menus from '@/views/wbkj/MenusSet.vue'
import Departs from '@/views/wbkj/DepartsSet.vue'
import Links from '@/views/wbkj/LinksSet.vue'
import Images from '@/views/wbkj/ImagesSet.vue'
import UserLogin from '@/views/UserLogin.vue'
import DocPanel from '@/views/wbkj/DocPanelSet.vue'
import CusPanel from '@/views/wbkj/CusPanelSet.vue'
import Role from '@/views/wbkj/RoleSet.vue'
import IpPermit from '@/views/wbkj/IpPermitSet.vue'
import AdminSet from '@/views/wbkj/AdminSet.vue' //后台账号管理
import UserInfo from '@/views/wbkj/UserInfo.vue' //
import Company from '@/views/wbkj/CompanySet.vue'
import Employee from '@/views/wbkj/EmployeeSet.vue'
import BackUp from '@/views/wbkj/BackUpSet.vue'//备份管理
import SettingInfo from '@/views/wbkj/SettingInfo.vue'//网站配置项
import TestCreate from '@/views/wbkj/TestCreateSet.vue'
import KindsSet from '@/views/wbkj/KindsSet.vue'  //统计分类
import FieldsSet from '@/views/wbkj/FieldsSet.vue'  //字段对照表
import DetailsSet from '@/views/wbkj/DetailsSet.vue'  //数据分类
import EvalUsers from '@/views/wbkj/EvalUsersSet.vue'
//import TechCostOut from '@/views/tech/CostOutSet.vue' //费用审批


Vue.use(VueRouter)
//2.将路由组用引入到路由中去,说白了就是路径和容器对应
const routes = [
  { path: '/', redirect: '/admin' }, //默认项
  { path: '/login', name: 'login', component: UserLogin },//登录
  {
    path: '/admin',
    component: AdminMain, //主容器
    redirect: '/homeIndex',
    children: [

      { path: '/homeIndex', name: 'homeIndex', component: HomeIndex },//首页
      { path: '/role', name: 'role', component: Role }, //角色管理
      { path: '/docInfo/:id', name: 'docInfo', component: DocInfo },
      { path: '/downloads', name: 'downloads', component: Downloads },
      { path: '/menus', name: 'menus', component: Menus },
      { path: '/departs', name: 'departs', component: Departs },
      { path: '/links', name: 'links', component: Links },
      { path: '/images', name: 'images', component: Images },
      { path: '/docPanel', name: 'docPanel', component: DocPanel },
      { path: '/cusPanel', name: 'cusPanel', component: CusPanel },
      { path: '/ipPermit', name: 'ipPermit', component: IpPermit },
      { path: '/adminSet', name: 'adminSet', component: AdminSet },
      { path: '/userInfo', name: 'userInfo', component: UserInfo },
      { path: '/company', name: 'company', component: Company }, //公司设定
      { path: '/employee', name: 'employee', component: Employee }, //员工设定
      { path: '/backUp', name: 'backUp', component: BackUp }, //请假
      { path: '/settingInfo', name: 'settingInfo', component: SettingInfo },
      { path: '/testCreate', name: 'testCreate', component: TestCreate },
      { path: '/kindsSet', name: 'kindsSet', component: KindsSet },
      { path: '/fieldsSet', name: 'fieldsSet', component: FieldsSet },
      { path: '/detailsSet', name: 'detailsSet', component: DetailsSet },
      { path: '/evalUsers', name: 'evalUsers', component: EvalUsers },

    ]
  }
]
const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})
// 4.把这个暴露出来,其它js才可以去使用它
export default router