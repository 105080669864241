<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="6">
        <el-select v-model="tableParam.kindId" filterable placeholder="请选择分类" style="width:100%;" ref="selectKind" @change="changeKind">
          <el-option v-for="(item,index) in this.allKinds" :key="index" :label="item.kindName" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%" @change="tableChangeSearchKey">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-button-group>
          <el-button @click="formDataNew" type="primary">新建</el-button>
          <el-button @click="formDataModify" type="primary">修改</el-button>
          <el-button @click="formDataDelete" type="primary">删除</el-button>
          <el-button @click="formDataBatchColor" type="success">批量标注</el-button>
        </el-button-group>
      </el-col>
    </el-row>

    <el-table ref="fieldsTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>

      <el-table-column header-align="center" label="字段名称" prop="fieldName" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.fieldName)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="英文名称" prop="enName" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.enName)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="标注" prop="isDefault" width="300">
        <template slot-scope="scope">
          <i class="el-icon-star-on" v-if="scope.row.isDefault" style="color:#67C23A;font-size:20px"></i>
          <i class="el-icon-star-on" v-else style="color: #efefef;font-size:20px"></i>

          <i class="el-icon-star-on" v-if="scope.row.isRed" style="color:red;font-size:20px"></i>
          <i class="el-icon-star-on" v-else style="color: #efefef;font-size:20px"></i>

          <i class="el-icon-star-on" v-if="scope.row.isOrange" style="color:orange;font-size:20px"></i>
          <i class="el-icon-star-on" v-else style="color: #efefef;font-size:20px"></i>

          <i class="el-icon-star-on" v-if="scope.row.isYellow" style="color:yellow;font-size:20px"></i>
          <i class="el-icon-star-on" v-else style="color: #efefef;font-size:20px"></i>

          <i class="el-icon-star-on" v-if="scope.row.isGreen" style="color:green;font-size:20px"></i>
          <i class="el-icon-star-on" v-else style="color: #efefef;font-size:20px"></i>

          <i class="el-icon-star-on" v-if="scope.row.isBlue" style="color:Blue;font-size:20px"></i>
          <i class="el-icon-star-on" v-else style="color: #efefef;font-size:20px"></i>

          <i class="el-icon-star-on" v-if="scope.row.isPurple" style="color:Purple;font-size:20px"></i>
          <i class="el-icon-star-on" v-else style="color: #efefef;font-size:20px"></i>

          <i class="el-icon-star-on" v-if="scope.row.isDark" style="color: #000;font-size:20px"></i>
          <i class="el-icon-star-on" v-else style="color: #efefef;font-size:20px"></i>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="主键" prop="isLock" width="60">
        <template slot-scope="scope">
          <span v-if="scope.row.isLock">
            <i class="el-icon-key" style="color:#67C23A;font-size:20px"></i>
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="排序" prop="sort" width="100"></el-table-column>

    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag class="customFileListCss" :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width"
      :top="formDialogParam.top" :close-on-click-modal='false'>
      <el-form ref="fieldsFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="字段名称" prop="fieldName">
          <el-input id="fieldName" v-model="formData.fieldName" placeholder="请输入字段名称"></el-input>
        </el-form-item>
        <el-form-item label="锁定唯一">
          <el-checkbox v-model="formData.isLock">唯一</el-checkbox>
        </el-form-item>
        <el-form-item label="标注">
          <el-checkbox-button v-model="formData.isDefault" label="isDefault"><i class="el-icon-star-on"
              style="color:#67C23A;font-size:20px"></i></el-checkbox-button>
          <el-checkbox-button v-model="formData.isRed"><i class="el-icon-star-on" style="color:red;font-size:20px"></i></el-checkbox-button>
          <el-checkbox-button v-model="formData.isOrange"><i class="el-icon-star-on" style="color:orange;font-size:20px"></i></el-checkbox-button>
          <el-checkbox-button v-model="formData.isYellow"><i class="el-icon-star-on" style="color:yellow;font-size:20px"></i></el-checkbox-button>
          <el-checkbox-button v-model="formData.isGreen"><i class="el-icon-star-on" style="color:green;font-size:20px"></i></el-checkbox-button>
          <el-checkbox-button v-model="formData.isBlue"><i class="el-icon-star-on" style="color:blue;font-size:20px"></i></el-checkbox-button>
          <el-checkbox-button v-model="formData.isPurple"><i class="el-icon-star-on" style="color:Purple;font-size:20px"></i></el-checkbox-button>
          <el-checkbox-button v-model="formData.isDark"><i class="el-icon-star-on" style="color:#000;font-size:20px"></i></el-checkbox-button>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number id="sort" v-model="formData.sort" controls-position="right" :precision="2" :step="0.1" :min="0" :max="2147483647.00"
            placeholder="请输入排序"></el-input-number>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="50%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="fieldName" label="字段名称">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-row :gutter="20" style="padding-top:0;">
          <el-col :span="14">
            <label v-if="formChooseDialogParam.batchColorVisible" style="color:#409EFF;margin-right:5px;">选择色彩:</label>
            <el-select v-if="formChooseDialogParam.batchColorVisible" v-model="formChooseDialogParam.selectField" placeholder="请选择色彩">
              <el-option value="isDefault" label="默认">
                <span style="float: left">默认</span>
                <span style="float: right"><i class="el-icon-star-on" style="color:#67C23A;font-size:20px"></i></span>
              </el-option>
              <el-option value="isRed" label="红色">
                <span style="float: left">红色</span>
                <span style="float: right"><i class="el-icon-star-on" style="color:red;font-size:20px"></i></span>
              </el-option>
              <el-option value="isOrange" label="橙色">
                <span style="float: left">橙色</span>
                <span style="float: right"><i class="el-icon-star-on" style="color:orange;font-size:20px"></i></span>
              </el-option>
              <el-option value="isYellow" label="黄色">
                <span style="float: left">黄色</span>
                <span style="float: right"><i class="el-icon-star-on" style="color:yellow;font-size:20px"></i></span>
              </el-option>
              <el-option value="isGreen" label="绿色">
                <span style="float: left">绿色</span>
                <span style="float: right"><i class="el-icon-star-on" style="color:green;font-size:20px"></i></span>
              </el-option>
              <el-option value="isBlue" label="蓝色">
                <span style="float: left">蓝色</span>
                <span style="float: right"><i class="el-icon-star-on" style="color:blue;font-size:20px"></i></span>
              </el-option>
              <el-option value="isPurple" label="紫色">
                <span style="float: left">紫色</span>
                <span style="float: right"><i class="el-icon-star-on" style="color:Purple;font-size:20px"></i></span>
              </el-option>
              <el-option value="isDark" label="黑色">
                <span style="float: left">黑色</span>
                <span style="float: right"><i class="el-icon-star-on" style="color:#000;font-size:20px"></i></span>
              </el-option>

            </el-select>
          </el-col>
          <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
          <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
          <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
        </el-row>

      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>

import store from '@/store';
import { getTableDataByKindId, doNew, doModify, doDelete, doMark } from '@/api/wbkj/Fields'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { getAllKinds } from '@/api/wbkj/Kinds';

import { required, phone, len, email } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: {},
  data () {
    return {
      allKinds: [],//所有的分类
      /*导航栏状态 */
      direcParam: {
        'directName': '名称名称名称名称名称名称名称名称名称Fields'
      },

      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'kindId': 0,
        'kindName': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '50%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        batchColorVisible: false,
        selectField: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        kindId: 0,
        kindName: '',
        fieldName: '',
        enName: '',
        isAdd: 0,
        isDefault: false,
        isRed: false,
        isOrange: false,
        isYellow: false,
        isGreen: false,
        isBlue: false,
        isPurple: false,
        isDark: false,
        isLock: false,
        sort: 0.00,

      },
      formDialogRules:
      {
        kindId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        kindName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        fieldName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        enName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isAdd: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isDefault: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isRed: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isOrange: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isYellow: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isGreen: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isBlue: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isPurple: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isDark: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isLock: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {

    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      if (this.tableParam.kindId > 0) {
        this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
        this.formData.kindId = this.tableParam.kindId;
        this.formData.kindName = this.tableParam.kindName;
        this.formDialogParam.title = '新建';
        this.formDialogParam.formDataAction = 'new';
        this.formDialogParam.visible = true;
      }
      else {
        this.notifyInfo("您只能先选一个分类,才能修改");
      }

    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },

    /*表单(form)-着色 */
    formDataBatchColor () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.batchColorVisible = true;
        this.formChooseDialogParam.selectField = '';
        this.formChooseDialogParam.title = "批量着色标注/取消标注"
        this.formChooseDialogParam.formChooseAction = 'color';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '取消标注';
        this.formChooseDialogParam.action2 = true;
        this.formChooseDialogParam.action2View = '标注';
        this.formChooseDialogParam.visible = true;
      }
    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.batchColorVisible = false;
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(已发布的不删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDataValidate () {
      let result = true;
      this.$refs.fieldsFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit (result) {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.formChooseDialogParam.visible = true;
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
      else if (this.formChooseDialogParam.formChooseAction == 'color') {
        if (!this.formChooseDialogParam.selectField) {
          this.formChooseDialogParam.visible = true;
          this.notifyWarning("请选择色彩");
          return
        }
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        data.append('fieldName', this.formChooseDialogParam.selectField);
        data.append('result', result);

        doMark(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      console.log(row)
      this.$refs.fieldsTableRef.clearSelection(); //清除选中
      this.$refs.fieldsTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableDataByKindId(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },
    changeKind (val) {
      this.tableParam.kindId = val;
      this.$nextTick(() => {
        this.tableParam.kindName = this.$refs.selectKind.selectedLabel;//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
        this.tableDateRefresh();
      })
    },

  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    getAllKinds().then(response => {
      if (response.data.success) {
        this.allKinds = response.data.data;
        if (this.allKinds.length > 0) {
          this.tableParam.kindId = this.allKinds[0].id;
          this.tableParam.kindName = this.allKinds[0].kindName;
          this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
          this.tableDateRefresh();
        }
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });

  }
}
</script>
