import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个Kinds接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/kinds/list', { 'params': tableParam })
}
export const getAllKinds = () => {
    return httpJson.get('/kinds/all')
}
export const getInfo = (param) => {
    return httpJson.get('/kinds/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/kinds/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/kinds/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/kinds/delete', formData)
}


