import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个Fields接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/fields/list', { 'params': tableParam })
}
export const getTableDataByKindId = (tableParam) => {
    return httpJson.get('/fields/listByKindId', { 'params': tableParam })
}
export const getAllDataByKindId = (tableParam) => {
    return httpJson.get('/fields/allByKindId', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/fields/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/fields/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/fields/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/fields/delete', formData)
}
export const doMark = (formData) => {
    return httpForm.post('/fields/mark', formData)
}


