import { httpJson } from '@/utils/adminRequest'
export const getCompanyAll = () => { //得到全部公司信息
  return httpJson.get('/company/allList')
}
export const getCompanyMain = () => { //得到主要公司信息(非关联)
  return httpJson.get('/company/mainList')
}

export const getCompanyLink = () => { //得到主要公司信息(非关联)
  return httpJson.get('/company/linkList')
}