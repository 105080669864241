<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="19">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: this.$store.getters.getLoginUser().home }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%" @change="tableChangeSearchKey" clearable>
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row v-if="checkCusRight(direcParam.id,'new')||checkCusRight(direcParam.id,'modify')||checkCusRight(direcParam.id,'delete')">
      <el-button-group>
        <el-button @click="formDataNew" v-if="checkCusRight(direcParam.id,'new')" type="primary">新建</el-button>
        <el-button @click="formDataModify" v-if="checkCusRight(direcParam.id,'modify')" type="primary">修改</el-button>
        <el-button @click="formDataDelete" v-if="checkCusRight(direcParam.id,'delete')" type="primary">删除</el-button>
      </el-button-group>
    </el-row>

    <el-table ref="employeeTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" prop="cmpName" label="所属公司" width="300" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.cmpName)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="depName" label="部门" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.depName)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="empName" label="员工姓名" width="100">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.empName)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="empCode" label="员工工号" width="100">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.empCode)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="empSex" label="性别" width="60">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.empSex)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="empEmail" label="E-mail" width="300">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.empEmail)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="empMobile" label="手机号码" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.empMobile)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="empWorkPhone" label="工作手机" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.empWorkPhone)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="inOffice" label="状态" width="80">

        <template slot-scope="scope">
          <span style="padding-left: 4px;" v-if="scope.row.inOffice">
            <el-link :underline="false">
              <el-tooltip class="item" effect="light" content="正式员工" placement="top-start">
                <i class="el-icon-user-solid" style="font-size:16px;color: #409EFF;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
          <span style="padding-left: 4px;" v-else>
            <el-link :underline="false">
              <el-tooltip class="item" effect="light" content="非正式员工" placement="top-start">
                <i class="el-icon-user-solid" style="font-size:16px;color: #999999;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>

          <span style="padding-left: 4px;" v-if="scope.row.onPosition">
            <el-link :underline="false">
              <el-tooltip class="item" effect="light" :content="'入职时间:'+scope.row.empEnterDate+'职务:'+scope.row.nowPosition" placement="top-start">
                <i class="el-icon-s-cooperation" style="font-size:16px;color: #409EFF;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
          <span style="padding-left: 4px;" v-else>
            <el-link :underline="false">
              <el-tooltip class="item" effect="light" content="离职" placement="top-start">
                <i class="el-icon-s-cooperation" style="font-size:16px;color: #999999;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag class="customFileListCss" :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width"
      :top="formDialogParam.top" :close-on-click-modal='false'>
      <el-form ref="employeeFormRef" :model="formData" :rules="formDialogRules" label-width="90px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-row style="padding-bottom: 0px;">
          <el-col :span="6">
            <el-form-item label="员工姓名" prop="empName">
              <el-input v-model="formData.empName" placeholder="请输入员工姓名"></el-input>
            </el-form-item>
            <el-form-item label="员工工号" prop="empCode">
              <el-input v-model="formData.empCode" placeholder="请输入员工工号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="英文名">
              <el-input v-model="formData.empEngName" placeholder="请输入员工英文名"></el-input>
            </el-form-item>
            <el-form-item label="E-mail" prop="empEmail">
              <el-input v-model="formData.empEmail" placeholder="请输入E-mail"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="性别" prop="empSex">
              <el-select v-model="formData.empSex" placeholder="请选择性别" style="width:100%">
                <el-option label="女" value="女">女</el-option>
                <el-option label="男" value="男">男</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号码" prop="empMobile">
              <el-input v-model="formData.empMobile" placeholder="请输入手机号码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出生年月" prop="empBirthday">
              <el-date-picker style="width:100%" v-model="formData.empBirthday" value-format="yyyy-MM-dd" type="date" placeholder="请选择出生年月"></el-date-picker>
            </el-form-item>
            <el-form-item label="工作手机" prop="empWorkPhone">
              <el-input v-model="formData.empWorkPhone" placeholder="请输入工作手机"></el-input>
            </el-form-item>

          </el-col>
        </el-row>
        <el-row style="padding-bottom: 0px;">
          <el-col :span="12">
            <el-form-item label="所属公司" prop="cmpId">

              <el-select v-model="formData.cmpId" ref="selectCmp" placeholder="请选择公司" @change="changeCmp" style="width:100%">
                <el-option v-for="item in this.allCmp" :key="'cmp'+item.id" :label="item.cmpCnName" :value='item.id'>{{ item.cmpCnName }}</el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :span="12">
            <el-form-item label="所属部门" prop="depId">
              <el-cascader v-model="formData.depId" :options="this.allDepart" @change="changeDep" placeholder="请选择部门" ref="depCascader"
                :props="this.allDepartProps" style="width:100%"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="padding-bottom: 0px;">
          <el-col :span="6">
            <el-form-item label="是否正式" prop="inOffice">
              <el-checkbox v-model="formData.inOffice">正式员工</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="入职时间" prop="empEnterDate">
              <el-date-picker v-model="formData.empEnterDate" style="width:100%" value-format="yyyy-MM-dd" type="date" placeholder="请选择入职时间"></el-date-picker>
            </el-form-item>

          </el-col>
          <el-col :span="6">
            <el-form-item label="是否在职" prop="onPosition">
              <el-checkbox v-model="formData.onPosition">在职</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="现任职位">
              <el-input v-model="formData.nowPosition" placeholder="请输入现任职位"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="通讯地址" prop="empAddress">
          <el-input v-model="formData.empAddress" placeholder="请输入通讯地址"></el-input>
        </el-form-item>

        <el-form-item label="备注">
          <el-input v-model="formData.note" type="textarea" :rows="3" placeholder="请输入备注"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="30%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="empName" label="选定的记录">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script> import store from '@/store';
import { getCompanyMain } from '@/api/utils/Company'
import { getTableData, postImg, doNew, doModify, doDelete, getAllDep } from '@/api/wbkj/Employee'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': '员工信息及账号管理'
      },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '60%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        cmpId: 0,
        cmpName: '',
        depId: '',
        depName: '',
        empName: '',
        empEngName: '',
        empCode: '',
        empSex: '男',
        empEmail: '',
        empAddress: '',
        empMobile: '',
        inOffice: false,
        onPosition: false,
        empEnterDate: new Date(),
        empBirthday: new Date(),
        empWorkPhone: '',
        nowPosition: '',
        note: '',

      },
      formDialogRules:
      {
        cmpId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        depId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        depName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empEngName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empCode: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empSex: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empEmail: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empDomicile: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empAddress: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empMobile: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        inOffice: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        onPosition: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empEnterDate: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empEdu: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empBirthday: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empLinkMan: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empLinkMobile: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empWorkPhone: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        politics: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        nationality: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        height: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        weight: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        marry: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        registedPlace: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        nowPosition: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        nowDrive: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        note: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
      allNation: ["汉族", "蒙古族", "回族", "藏族", "维吾尔族", "苗族", "彝族", "壮族", "布依族", "朝鲜族", "满族", "侗族", "瑶族", "白族", "土家族", "哈尼族", "哈萨克族", "傣族", "黎族", "傈僳族", "佤族", "畲族", "高山族", "拉祜族", "水族", "东乡族", "纳西族", "景颇族", "柯尔克孜族", "土族", "达斡尔族", "仫佬族", "羌族", "布朗族", "撒拉族", "毛南族", "仡佬族", "锡伯族", "阿昌族", "普米族", "塔吉克族", "怒族", "乌孜别克族", "俄罗斯族", "鄂温克族", "德昂族", "保安族", "裕固族", "京族", "塔塔尔族", "独龙族", "鄂伦春族", "赫哲族", "门巴族", "珞巴族", "基诺族"],
      allEdu: ["小学", "初中", "高中", "大专", "本科", "硕士", "博士"],
      allDriver: ["没有驾照", "A1驾驶证", "A2驾驶证", "A3驾驶证", "B1驾驶证", "B2驾驶证", "C1驾驶证", "C2驾驶证", "C3驾驶证", "C4三轮汽车", "C5驾驶证"],
      allPlace: ["北京市", "天津市", "上海市", "重庆市", "河北省", "山西省", "黑龙江省", "吉林省", "辽宁省", "江苏省", "浙江省", "安徽省", "福建省", "江西省", "山东省", "河南省", "湖北省", "湖南省", "广东省", "海南省", "四川省", "贵州省", "云南省", "陕西省", "甘肃省", "青海省", "台湾省", "内蒙古自治区", "广西壮族自治区", "西藏自治区", "宁夏回族自治区", "新疆维吾尔自治区", "香港特别行政区", "澳门特别行政区"],
      allPolitics: ["群众", "共青团员", "中共预备党员", "中共党员", "民革党员", "民盟盟员", "民进会员", "农工党党员", "致公党党员", "九三学社社员", "台盟盟员"],
      allCmp: [],
      allDepart: [],
      allDepartProps: {
        label: 'depName',
        value: 'id',
        children: 'bodyItems',
        multiple: false,
        emitPath: false,
      }
    }
  },
  methods: {
    changeCmp (cmpId) {
      this.$nextTick(() => {
        this.formData.cmpName = this.$refs.selectCmp.selectedLabel;//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
      })
      this.formData.depId = 0;
      this.getAllDepData(cmpId);
    },
    changeRole () {
      this.$nextTick(() => {
        this.formData.roleName = this.$refs.selectRole.selectedLabel;//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
      })
    },
    getAllDepData (cmpId) {
      getAllDep({ cmpId: cmpId }).then(response => {
        if (response.data.success) {
          this.allDepart = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    },
    changeDep () {
      const checkedNodes = this.$refs['depCascader'].getCheckedNodes() // 获取当前点击的节点
      this.formData.depName = checkedNodes[0].pathLabels.join('/');
    },


    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.getAllDepData(this.formData.cmpId);
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(已发布的不删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    /*表单(form)-发布示例 */
    /*formDataPublic () {
        if (this.tableMultipleSelection.length < 1) {
            this.notifyInfo("您必须最少选择一条记录,才能发布");
        }
        else {
            this.formChooseDialogParam.title = "将选定的记录,发布/取消发布"
            this.formChooseDialogParam.formChooseAction = 'public';
            this.formChooseDialogParam.action1 = true;
            this.formChooseDialogParam.action1View = '取消发布';
            this.formChooseDialogParam.action2 = true;
            this.formChooseDialogParam.action2View = '发 布';
            this.formChooseDialogParam.visible = true;
        }
    },*/
    formDataValidate () {
      let result = true;
      this.$refs.employeeFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));

      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      this.$refs.employeeTableRef.clearSelection(); //清除选中
      this.$refs.employeeTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },
  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

    this.direcParam = JSON.parse(store.getters.getActiveCus());

    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.tableDateRefresh();
    //取得全部公司
    getCompanyMain().then(response => {
      if (response.data.success) {
        this.allCmp = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
  }
}
</script>
