<template>
  <span>
    <span>
      <el-link v-for="(item,index) in pdfList" :key="'pdf'+index" type="primary" style="padding-left: 4px;" @click="showPdf(item.fileName,item.serverPath,true)"
        :underline="false">
        <el-tooltip class="item" effect="light" :content="`点击查看:${item.fileName}.${item.extName}`" placement="top-start">
          <el-image style="width: 20px; height: 20px" :src="pdfViewUrl"></el-image>
        </el-tooltip>
      </el-link>
      <el-link v-if="imageList.length>0" type="primary" style="padding-left: 4px;" :underline="false">
        <el-tooltip class="item" effect="light" content="点击查看所有的图片信息" placement="top-start">
          <el-image style="width: 20px; height: 20px;" :src="imageViewUrl" :preview-src-list="imageList"></el-image>
        </el-tooltip>
      </el-link>
      <el-link v-for="(item,index) in downList" :key="'down'+index" type="primary" style="padding-left: 4px;" :underline="false"
        @click="downloadFile(item.serverPath,item.fileName)">
        <el-tooltip class="item" effect="light" :content="`点击下载:${item.fileName}.${item.extName}`" placement="top-start">
          <i class="el-icon-paperclip" style="font-size:20px;"></i>
        </el-tooltip>
      </el-link>
    </span>
    <el-dialog v-dialogDrag top="3vh" :title="this.pdfViewParam.title" :visible.sync="pdfViewParam.visible" width="80%" :close-on-click-modal='false'
      append-to-body>
      <pdf-view :pdfUrl="this.pdfViewParam.pdfUrl" style="height: 800px;"></pdf-view>
    </el-dialog>
  </span>
</template>
 <script> 
import store from '@/store';
import PdfView from '@/components/utils/PdfView.vue';
export default {
  props: ['fileList'],
  components: { PdfView },
  data () {
    return {
      pdfViewUrl: '/icon/status/pdf.png',
      imageViewUrl: '/icon/status/picture.png',
      imageList: [],
      pdfList: [],
      downList: [],
      pdfViewParam: {
        url: '',
        title: '',
        visible: false
      },
    }
  },
  methods: {
    showPdf (title, pdfUrl, visible) {
      this.pdfViewParam.title = title;
      this.pdfViewParam.pdfUrl = pdfUrl + '?downToken=' + store.getters.getToken();
      this.pdfViewParam.visible = visible
    },
  },
  watch: {
    fileList: {
      deep: true,
      immediate: true, // 监听到后，立即执行 handler方法
      handler: function () {
        this.imageList = [];
        this.pdfList = [];
        this.downList = [];
        if (this.fileList) {
          for (let i = 0; i < this.fileList.length; i++) {
            if (this.fileList[i].extName == "png" || this.fileList[i].extName == "jpg" || this.fileList[i].extName == "gif") {
              this.imageList.push(this.fileList[i].serverPath + '?downToken=' + this.$store.getters.getToken());
            }
            else if (this.fileList[i].extName == "pdf") {
              this.pdfList.push(this.fileList[i]);
            }
            else {
              this.downList.push(this.fileList[i]);
            }
          }
        }
      },
    },
  }
}
</script>