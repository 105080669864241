<!--  -->
<template>
  <el-menu mode="horizontal" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
    <el-menu-item v-if="isViewPoint" @click="hidenShowLeftMenu" class="menuItemByShowOrHiden"><i :class="`${iconClass}`"></i>
    </el-menu-item>
    <el-menu-item v-else style="width:200px">
      <h3 style="display:flex;line-height:60px;"><img src='/icon/logo2.png' width="50px" style="margin-right:10px" /></h3>
    </el-menu-item>
    <menu-panel :data="node" :float="'left'" :view-sub="true" v-for="node in topLeftMenu" :key="'top_left_'+node.id"></menu-panel>
    <el-submenu index="userInfo" style="float:right">
      <template slot="title"><i class="el-icon-s-custom" style="color:#409eff">&nbsp;</i>{{loginUser.loginName}}</template>
      <el-menu-item index="userInfo" @click="userInfo">用户中心</el-menu-item>
      <el-menu-item index="loginOut" @click="loginOut">退出系统</el-menu-item>
    </el-submenu>
    <menu-panel :data="node" :float="'right'" :view-sub="false" v-for="node in topRightMenu" :key="'top_right_'+node.id"></menu-panel>
  </el-menu>
</template>
<style lang="less" scoped>
.menuItemByShowOrHiden {
  padding: 0px;
}
</style>
<script> 
import store from '@/store';
import MenuPanel from '../utils/MenuPanel.vue';

export default {
  components: { MenuPanel },
  props: ['topLeftMenu', 'topRightMenu', 'isViewPoint'],
  data () {
    return {
      settingData: {
        siteTitle: "昆明微布科技有限公司",
        siteSub: "管理系统",
        sysName: '管理后端',
        pathEnable: false,
        pathUrl: "http://www.luoui.com/"
      },
      iconHiden: false,
      iconClass: "el-icon-caret-left",
      menuData: [],
      loginUser: store.getters.getLoginUser()
    };
  },
  //方法集合
  methods: {
    hidenShowLeftMenu () {
      this.$data.iconStatus = !this.$data.iconStatus;
      if (this.$data.iconStatus) {
        this.$data.iconClass = "el-icon-caret-right";
      }
      else {
        this.$data.iconClass = "el-icon-caret-left";
      }
      this.$store.commit('cllapseMenu');
    },
    userInfo () {
      if (!(this.$route.name == 'userInfo')) {
        this.$router.replace({
          name: 'userInfo',
        });
      }
    },
    loginOut () {
      this.$store.commit("delToken");
      this.$store.commit("delLoginUser");
      this.$router.push('/login')
    }
  }
}
</script>